import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={styles["main"]}>
      <span>
        Created by <span className={styles["highlighted"]}>Vaibhav</span> |
        &#169; 2022
      </span>
    </div>
  );
};

export default Footer;
