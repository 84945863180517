import Button from "../../ui/Button/Button";
import styles from "./MainSection.module.css";
// import { LINK_RESUME } from "../../../utils/urls";

const MainSection = () => {
  const LINK_RESUME = process.env.REACT_APP_RESUME_LINK;
  const contactButtonClick = () => {
    const section = document.querySelector("#contact");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const downloadCVButtonClick = () => {
    window.open(LINK_RESUME);
  };
  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <div className={styles["left-main"]}>
          <span className={styles["left-heading"]}>
            Hello!<br></br> I'm Vaibhav
          </span>
          <span className={styles["left-about"]}>
            I’m a freelance <span>Full Stack Web Developer</span> based in{" "}
            <span>Jaipur, India</span> who loves to craft engaging design
            experiences for the web.
          </span>
          <div className={styles["left-button"]}>
            <Button
              onClick={downloadCVButtonClick}
              img="/download.svg"
              text="Download CV"
            />
          </div>
        </div>
      </div>
      <div className={styles.right}></div>
      <div className={styles["contact-button"]}>
        <Button onClick={contactButtonClick} text="Contact" />
      </div>
    </div>
  );
};

export default MainSection;
