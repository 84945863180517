import "./App.css";
import MainPage from "./components/individual/MainPage/MainPage";
import analytics from "./utils/firebase_analytics";
import { inject } from "@vercel/analytics";
import { Analytics } from "@vercel/analytics/react";
function App() {
  inject();
  return (
    <>
      <Analytics />
      <MainPage />
    </>
  );
}

export default App;
