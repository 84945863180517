// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6VmogpQS7ieZbu8YpBCNk6b9GLCNlt6Q",
  authDomain: "myportfolio-ff1cb.firebaseapp.com",
  projectId: "myportfolio-ff1cb",
  storageBucket: "myportfolio-ff1cb.appspot.com",
  messagingSenderId: "311494194331",
  appId: "1:311494194331:web:d19729d526738bceae023e",
  measurementId: "G-W87ECSM2CV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;
