import styles from "./Connect.module.css";
import Button from "../../ui/Button/Button";
import useHttp from "../../../hooks/use-http";
import * as React from "react";
import { useState, useEffect } from "react";
import SuccessSnackBar from "../../ui/SnackBars/SuccessSnackBar";
import ErrorSnackBar from "../../ui/SnackBars/ErrorSnackBar";
import Loader from "../../ui/Loader";

import {
  LINK_GITHUB,
  LINK_LINKEDIN,
  LINK_TWITTER,
  LINK_INSTA,
  LINK_SEND_EMAIL,
} from "../../../utils/urls";

import {
  MESSAGE_ERROR_EMAIL,
  MESSAGE_ERROR_EMPTY_FIELDS,
  MESSAGE_SUCCESS,
} from "../../../utils/messages";

const Connect = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [inputError, setInputError] = useState(false);
  const [emailSendError, setEmailSendError] = useState(false);
  const { data, post, loading, error } = useHttp();
  const nameChangeHandler = (e) => {
    setName(e.target.value);
  };
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };
  const submitFormHandler = async () => {
    if (name === "" || email === "" || message === "") {
      setInputError(true);
      return;
    }
    const configs = { url: LINK_SEND_EMAIL, body: { name, email, message } };
    await post(configs);
  };

  useEffect(() => {
    if (data) {
      if (data.data.msg === "SUCCESS") {
        setEmailSendError(false);
        setName("");
        setEmail("");
        setMessage("");
      } else {
        setEmailSendError(true);
      }
    }
  }, [data]);

  return (
    <div id="contact" className={styles.main}>
      <div className={styles.left}>
        <span>Let's Connect</span>
        <div className={styles.icons}>
          <a href={LINK_LINKEDIN} target="_blank" rel="noopener noreferrer">
            <img src="/linkedin.svg"></img>
          </a>
          <a href={LINK_GITHUB} target="_blank" rel="noopener noreferrer">
            <img src="/github.svg"></img>
          </a>
          <a href={LINK_TWITTER} target="_blank" rel="noopener noreferrer">
            <img src="/twitter_f.svg"></img>
          </a>
          <a href={LINK_INSTA} target="_blank" rel="noopener noreferrer">
            <img src="/insta.svg"></img>
          </a>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles["connect-form-inputs"]}>
          <div className={styles["each-input"]}>
            <label htmlFor="name">
              Name<span className={styles.red}>*</span>
            </label>
            <input
              placeholder="John Doe"
              id="name"
              type="text"
              onChange={nameChangeHandler}
              value={name}
            ></input>
          </div>
          <div className={styles["each-input"]}>
            <label htmlFor="email">
              Email<span className={styles.red}>*</span>
            </label>
            <input
              placeholder="johndoe@gmail.com"
              id="email"
              type="text"
              onChange={emailChangeHandler}
              value={email}
            ></input>
          </div>
          <div className={styles["each-input"]}>
            <label htmlFor="message">
              Message<span className={styles.red}>*</span>
            </label>
            <textarea
              className={styles.message}
              placeholder="Anything I can help you with? Let me know"
              id="message"
              type="text"
              onChange={messageChangeHandler}
              value={message}
            ></textarea>
          </div>
        </div>
        <div
          onClick={submitFormHandler}
          className={styles["connect-form-submit"]}
        >
          <Button img="/email.svg" text="Send" disabled={loading} />
        </div>
      </div>
      {!emailSendError && data && !loading && !error && (
        <SuccessSnackBar message={MESSAGE_SUCCESS} />
      )}
      {emailSendError && data && !loading && (
        <ErrorSnackBar
          setInputError={emailSendError}
          message={MESSAGE_ERROR_EMAIL}
        />
      )}
      {inputError && (
        <ErrorSnackBar
          setInputError={setInputError}
          message={MESSAGE_ERROR_EMPTY_FIELDS}
        />
      )}

      {loading && <Loader />}
    </div>
  );
};

export default Connect;
