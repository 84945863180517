import styles from "./Button.module.css";
import CircularProgress from "@mui/material/CircularProgress";

const ImgButton = (props) => {
  return (
    <div onClick={props.onClick} className={styles.main}>
      {props.img && <img src={props.img}></img>}
      <div>{props.text}</div>
    </div>
  );
};

export default ImgButton;
