import Connect from "../Connect/Connect";
import MainSection from "../MainSection/MainSection";
import MyProjects from "../MyProjects/MyProjects";
import MySkills from "../MySkills/MySkills";
import Footer from "../Footer/Footer";
import styles from "./MainPage.module.css";
const MainPage = () => {
  return (
    <div className={styles.main}>
      <MainSection />
      <MySkills />
      <MyProjects />
      <Connect />
      <Footer />
    </div>
  );
};

export default MainPage;
