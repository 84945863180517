import styles from "./EachSkill.module.css";

const EachSkill = (props) => {
  return (
    <div className={styles.main}>
      <img src={props.img}></img>
      <span>{props.children}</span>
    </div>
  );
};

export default EachSkill;
