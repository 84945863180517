export const images = [
  "/skills_icons/html.svg",
  "/skills_icons/css.svg",
  "/skills_icons/js4.svg",
  "/skills_icons/react.svg",
  "/skills_icons/mongo2.svg",
];
export const titles = [
  "\u00A0HTML \u00A0",
  "CSS \u00A0",
  "JS\u00A0\u00A0",
  "REACT",
  "MONGO",
];
