export const projects = [
  {
    cover: "project_headers/phoenix2.png",
    title: "Phoenix",
    description:
      "I mentored over the development of my institute's Robotics Club's website.  Code can't be shared due to security reasons.",
    techStack: ["HTML", "CSS", "JavaScript", "React"],
    previewLink: "http://phoenix.lnmiit.ac.in/",
  },
  {
    cover: "project_headers/reBuy3.jpg",
    title: "ReBuy",
    description:
      "A full stack clone of OLX. It allows users to buy and sell products. It has limited features as it was created for learning purposes.",
    techStack: ["MongoDB", "Express", "React", "NodeJS"],
    previewLink: "https://re6uy.netlify.app/",
    codeLink: "https://github.com/thecurious1-sudo/ReBuy-frontend",
  },
  {
    cover: "project_headers/livedoc2.png",
    title: "LiveDoc",
    description:
      "This project was created during BITS Pilani Postman 2.0 Hackathon. It allows patients to get the diagnosis of their symptoms. This project uses free apis.",
    techStack: ["HTML", "CSS", "JavaScript", "React"],
    previewLink: "https://livedoc.netlify.app/",
    codeLink: "https://github.com/thecurious1-sudo/LiveDoc",
  },
  {
    cover: "project_headers/yelpcamp2.png",
    title: "YelpCamp",
    description:
      "This project was created during Colt Steele's Web Developer Bootcamp. It's a full-stack campground review website with authentication and authorization.",
    techStack: ["HTML", "CSS", "JS", "Express", "EJS", "MongoDB"],
    previewLink: "https://yelpcamps.cyclic.app/",
    codeLink: "https://github.com/thecurious1-sudo/YelpCamp",
  },
  {
    cover: "project_headers/yomamma2.png",
    title: "Yomamma Jokes",
    description:
      "A simple web app that generates random yomamma jokes using an API. \u00A0 It was built to test the custom http request hook I built.",
    techStack: ["HTML", "CSS", "JavaScript", "React", "Express"],
    previewLink: "https://yomamma.netlify.app/",
    codeLink: "https://github.com/thecurious1-sudo/yomamma",
  },
  {
    cover: "project_headers/employeejet2.jpg",
    title: "Employee.jet",
    description:
      "An employee engagement platform for improving workplace experience through increased morale, productivity, and alignment.",
    techStack: ["HTML", "CSS", "JS", "Express", "EJS", "MongoDB"],
    codeLink: "https://github.com/thecurious1-sudo/Employee.jet_ALL",
  },
  {
    cover: "project_headers/devChallenges2.jpg",
    title: "Frontend Designs",
    description:
      "This includes various frontend designs I created while practising my frontend development skills.",
    techStack: ["HTML", "CSS"],
    codeLink: "https://github.com/thecurious1-sudo/DevChallenges-responsive",
  },

  // {
  //   cover: "project_headers/test_each_project.svg",
  //   title: "Project Title",
  //   description:
  //     "This is sample project description random things are here in description This is sample project lorem ipsum generator for dummy content",
  //   techStack: ["HTML", "CSS", "JavaScript", "React"],
  //   previewLink: "https://www.google.com",
  //   codeLink: "https://www.google.com",
  // },
];
