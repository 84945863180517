import EachSkill from "../EachSkill/EachSkill";
import styles from "./MySkills.module.css";
import { images, titles } from "../../../utils/skills.js";

const MySkills = () => {
  return (
    <div className={styles.main}>
      {images.map((image, i) => {
        return (
          <EachSkill key={i} img={image}>
            {titles[i]}
          </EachSkill>
        );
      })}
    </div>
  );
};

export default MySkills;
